html, body {
    color: #656464;
    font-family: Tofino, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.471;
    max-width: 100%;
    overflow-x: hidden;
}

body {
    min-height: 100vh;
    flex-direction: column;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #003150fc;
}

main {
    flex: 1 0 auto;
}

.row > .row {
    margin-left: 0px;
}

a {
    text-decoration: none;
    color: #656464;
}

    a:hover {
        color: #003150fc 
    }

p {
    margin-top: 0;
    margin-bottom: 0;
}

label {
    min-height: 20px;
    margin-bottom: 0;
    font-weight: 400;
}

svg {
    cursor: pointer;
}

    svg.rounded-circle{
        cursor: default;
    }

strong, b {
    font-weight: bold;
}

.w-100{
    height: 20.59px;
}
.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

    .btn-default:hover {
        color: #FFF;
        background-color: #003150fc;
        border-color: #003150fc;
    }

.btn-save {
    color: #fff;
    background-color: #ff9330;
    border-color: #ff9330;
}

    .btn-save:hover {
        color: #FFF;
        background-color: #003150fc;
        border-color: #003150fc;
    }

.btn-new {
    color: #fff;
    background-color: #656464;
    border-color: #656464;
}

    .btn-new:hover {
        color: #FFF;
        background-color: #003150fc;
        border-color: #003150fc;
    }

.btn-disable {
    color: #333;
    background-color: #6564645c;
    border-color: #ccc;
}

.select-adm-user {
    border-radius: 0;
    border-left: 0;
}

.form-group-button{
    display: flex;
    align-items: end;
    margin-bottom: 10px;
}

.btn-group{
    margin-bottom: 15px;
}

.btn-group.actions{
    display: block;
}

.page-title {
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 25px;
    color: #ff9330;
    margin-bottom: 20px;
}

.page-subtitle {
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 20px;
    color: #ff9330;
}


/* Header */

.logo {
    background: url('logo.png') no-repeat;
    width: 178px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    text-align: center;
}

.mini-logo {
    background: url('logo.png') no-repeat;
    height: 100%;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.navbar {
    margin-top: 5px !important;
}

.menu {
    display: inline-block;
    cursor: pointer;
}

/* Signed-in user */

.username {
    display: inline-block;
    line-height: 38px;
    vertical-align: top;
    width: calc(100% - 46px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

#sign-in-button {
    margin-left: 10px;
    color: inherit;
}

.panel {
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
}

.panel-device, .panel-sensor {
    border-radius: 10px;
    display: grid;
    background-color: #fff;
    min-height: 139px;
    margin: 0px 0px 21px 0px;
}


.panel-device > .row > div:last-child, .panel-sensor > .row > div:last-child {
    align-self: flex-end;
}

.panel-login {
    padding: 10px;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.panel-device, .panel-sensor > div {
    padding: 10px;
    z-index: 2;
}

.panel-login > div {
    margin-bottom: 5px;
    padding-top: 10px;
}

    .panel-menu > div > i, .panel-device > div > i, .panel-sensor > div > i, .panel-login > div > i {
        font-size: 25px;
    }

    .panel-login > div:last-child > span, a {
        font-size: 13px;
    }

    .panel-login > div:last-child > a {
        font-weight: bold;
        cursor: pointer;
    }

.panel-login .restoreText {
    white-space: pre-line;
}

.panel-device > div > i, .panel-device > p, .panel-sensor > div > i, .panel-sensor > p, .panel-menu > div > i, .panel-menu > p {
    color: grey;
    z-index: 2;
}


div[data-status=close] > div > div.panel-advance {
    display: block;
}

.panel-menu {
    border-radius: 10px;
    padding: 10px 0 10px 0;
    background-color: #fff;
}

.panel-menu-opcion {
    float: none;
    display: inline-block;
    text-align: center;
}

    .panel-menu-opcion:hover > * {
        cursor: pointer;
    }

    .panel-menu-opcion > p.link {
        margin: 0px 0px 0px !important;
    }

    .panel-menu > div > i:not(:first-child), .panel > div > i:not(:first-child) {
        float: right;
        cursor: pointer;
    }

    .panel > div > i.config {
        float: right;
    }

    .panel > p.name {
        margin-bottom: 5px;
    }

.activado {
    color: #EA8120;
    display: inline-block;
}

.desconectado {
    color: #656464;
    display: inline-block;
}

.description-device, .description-sensor {
    font-size: 25px;
    font-weight: bold;
    display: block;
}

.description-device.action, .description-sensor.action{
    cursor:pointer;
}

.tempIcon-Inside {
    position: absolute;
    left: 128px;
    z-index: 1000;
    top: 50px;
}

.tempSet {
    font-size: 18px;
}

label.description {
    float: left;
    padding-left: 0px !important;
    margin-bottom: 5px !important;
    width: 100%;
    cursor: default;
}

label.input {
    color: #ff9330;
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

input[type=color]{
    height: 34px;
}

.form-control:disabled{
    background-color: transparent;
    border-color: transparent;
    margin-left: -10px;
}

.status-disabled {
    color: #8080809c !important;
    vertical-align: middle;
}

.status-enabled {
    color: #EA8120 !important;
    vertical-align: middle;
}

.status-active {
    color: #FFFFFF !important;
    vertical-align: middle;
}

.status-inactive {
    color: #656464 !important;
    vertical-align: middle;
}


.form-button {
    width: auto !important;
    float: right;
}

.form-horizontal .control-label {
    padding-top: 8px
}

.form-control:focus {
    border-color: #CCD0D7;
    box-shadow: none !important
}

.form-date {
    border: 0px !important;
}

.form-switch .form-check-input {
    width: 40px;
    cursor:pointer;
}

.form-check-input {
    height: 20px;
}

    .form-check-input:checked {
        background-color: #EA8120;
        border-color: #EA8120;
    }

.form-check label {
    margin-left: 10px;
    margin-top: 3px;
}

legend {
    font-size: 18px;
    color: inherit
}

.form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0
}

.form-control-feedback {
    margin-top: 8px;
    height: 23px;
    color: #bbb;
    line-height: 24px;
    font-size: 15px
}

    .form-control-feedback.left {
        border-right: 1px solid #ccc;
        left: 13px
    }

    .form-control-feedback.right {
        border-left: 1px solid #ccc;
        right: 13px
    }

.form-group {
    margin-bottom: 10px
}

.validate {
    margin-top: 10px
}


.rs-range-color, .rs-handle-dot:after {
    background-color: #ff9330 !important;
}

.rs-control {
    margin-bottom: 26px;
}

.rs-control .rs-path-color {
    background-color: #f0f0f0;
}

.rs-border {
    border: 1px solid #ff9330;
}

.rs-top .rs-tooltip {
    top: 15px !important;
}

.rs-tooltip-text {
    font-size: 24px !important;
    font-weight: bold !important;
}

    .rs-tooltip-text > small {
        font-size: 20px !important;
        font-weight: 100 !important;
    }

.rs-handle {
    background-color: #003150fc !important;
}

/* Admin */

.nav > li > a {
    color: #FFFFFF;
    background: #2A3F54;
    overflow: hidden;
    margin: 2px;
    border-radius: 10px;
}

.nav > li.active > a, .nav > li.active > a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.custom-nav-statistics > .nav-link:focus, .nav-link:hover, .custom-nav-statistics > .nav-link[aria-expanded="true"] {
    color: #555;
    background-color: #fff;
}

.nav-link:focus, .nav-link:hover, .nav-link[aria-expanded="true"] {
    color: #EA8120;
    background-color: #2A3F54;
}

.divider {
    border-bottom: 1px solid #2A3F54;
    margin-bottom: 5px;
    margin-top: 5px;
}

/* Estadisticas */
.nav.custom-nav-statistics{
    border: 0px;
}

.custom-nav-statistics > li > a {
    margin: 0px;
    padding-bottom: 16px;
    
}

.custom-nav-statistics > *:first-child .nav-link{
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
}

.custom-nav-statistics > *:last-child .nav-link{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 5px;
}

.custom-nav-statistics > *:not(:first-child):not(:last-child) .nav-link{
    border-radius: 0px;
}

.statistics-filter {
    font-size: 18px;
    font-weight: bold;
    background: #EA8120;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    padding-top: 5px;
    float: right;
}

/* Loading */

.cssload-thecube {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    margin-top: 37px;
    position: relative;
    transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
}

    .cssload-thecube .cssload-cube {
        position: relative;
        transform: rotateZ(45deg);
        -o-transform: rotateZ(45deg);
        -ms-transform: rotateZ(45deg);
        -webkit-transform: rotateZ(45deg);
        -moz-transform: rotateZ(45deg);
    }

    .cssload-thecube .cssload-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
    }

        .cssload-thecube .cssload-cube:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(255,148,48);
            animation: cssload-fold-thecube 1.68s infinite linear both;
            -o-animation: cssload-fold-thecube 1.68s infinite linear both;
            -ms-animation: cssload-fold-thecube 1.68s infinite linear both;
            -webkit-animation: cssload-fold-thecube 1.68s infinite linear both;
            -moz-animation: cssload-fold-thecube 1.68s infinite linear both;
            transform-origin: 100% 100%;
            -o-transform-origin: 100% 100%;
            -ms-transform-origin: 100% 100%;
            -webkit-transform-origin: 100% 100%;
            -moz-transform-origin: 100% 100%;
        }

    .cssload-thecube .cssload-c2 {
        transform: scale(1.1) rotateZ(90deg);
        -o-transform: scale(1.1) rotateZ(90deg);
        -ms-transform: scale(1.1) rotateZ(90deg);
        -webkit-transform: scale(1.1) rotateZ(90deg);
        -moz-transform: scale(1.1) rotateZ(90deg);
    }

    .cssload-thecube .cssload-c3 {
        transform: scale(1.1) rotateZ(180deg);
        -o-transform: scale(1.1) rotateZ(180deg);
        -ms-transform: scale(1.1) rotateZ(180deg);
        -webkit-transform: scale(1.1) rotateZ(180deg);
        -moz-transform: scale(1.1) rotateZ(180deg);
    }

    .cssload-thecube .cssload-c4 {
        transform: scale(1.1) rotateZ(270deg);
        -o-transform: scale(1.1) rotateZ(270deg);
        -ms-transform: scale(1.1) rotateZ(270deg);
        -webkit-transform: scale(1.1) rotateZ(270deg);
        -moz-transform: scale(1.1) rotateZ(270deg);
    }

    .cssload-thecube .cssload-c2:before {
        animation-delay: 0.205s;
        -o-animation-delay: 0.205s;
        -ms-animation-delay: 0.205s;
        -webkit-animation-delay: 0.205s;
        -moz-animation-delay: 0.205s;
    }

    .cssload-thecube .cssload-c3:before {
        animation-delay: 0.42s;
        -o-animation-delay: 0.42s;
        -ms-animation-delay: 0.42s;
        -webkit-animation-delay: 0.42s;
        -moz-animation-delay: 0.42s;
    }

    .cssload-thecube .cssload-c4:before {
        animation-delay: 0.625s;
        -o-animation-delay: 0.625s;
        -ms-animation-delay: 0.625s;
        -webkit-animation-delay: 0.625s;
        -moz-animation-delay: 0.625s;
    }



@keyframes cssload-fold-thecube {
    0%, 10% {
        transform: perspective(105px) rotateX(-180deg);
        opacity: 0;
    }

    25%, 75% {
        transform: perspective(105px) rotateX(0deg);
        opacity: 1;
    }

    90%, 100% {
        transform: perspective(105px) rotateY(180deg);
        opacity: 0;
    }
}

@-o-keyframes cssload-fold-thecube {
    0%, 10% {
        -o-transform: perspective(105px) rotateX(-180deg);
        opacity: 0;
    }

    25%, 75% {
        -o-transform: perspective(105px) rotateX(0deg);
        opacity: 1;
    }

    90%, 100% {
        -o-transform: perspective(105px) rotateY(180deg);
        opacity: 0;
    }
}

@-ms-keyframes cssload-fold-thecube {
    0%, 10% {
        -ms-transform: perspective(105px) rotateX(-180deg);
        opacity: 0;
    }

    25%, 75% {
        -ms-transform: perspective(105px) rotateX(0deg);
        opacity: 1;
    }

    90%, 100% {
        -ms-transform: perspective(105px) rotateY(180deg);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-fold-thecube {
    0%, 10% {
        -webkit-transform: perspective(105px) rotateX(-180deg);
        opacity: 0;
    }

    25%, 75% {
        -webkit-transform: perspective(105px) rotateX(0deg);
        opacity: 1;
    }

    90%, 100% {
        -webkit-transform: perspective(105px) rotateY(180deg);
        opacity: 0;
    }
}

@-moz-keyframes cssload-fold-thecube {
    0%, 10% {
        -moz-transform: perspective(105px) rotateX(-180deg);
        opacity: 0;
    }

    25%, 75% {
        -moz-transform: perspective(105px) rotateX(0deg);
        opacity: 1;
    }

    90%, 100% {
        -moz-transform: perspective(105px) rotateY(180deg);
        opacity: 0;
    }
}

/* End Loading */


/* Notifications */
.list-notification {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
}
    .list-notification li {
        position: relative;
        border-bottom: 1px solid #e8e8e8;
        clear: both;
    }
        .list-notification li .content {
            margin-left: 5px;
            border-left: 3px solid #e8e8e8;
            overflow: visible;
            padding: 10px 15px;
        }

            .list-notification li .content .title {
                position: relative;
                font-size: 16px;
                margin: 0;
            }               

                .list-notification li .content .title:before {
                    content: "";
                    position: absolute;
                    left: -23px;
                    top: 3px;
                    display: block;
                    width: 14px;
                    height: 14px;
                    border: 3px solid #d2d3d2;
                    border-radius: 14px;
                    background: #f9f9f9;
                }

                .list-notification li .content .title.activado:before {
                    background: #EA8120
                }

            .list-notification li .content .description {
                padding: .25em 0;
                font-size: .9375em;
                line-height: 1.3;
                color: #aab6aa;
            }

.notification-sphere, .statistics-sphere {
    display: flow-root;
    border-radius: 50%;
    height: 106px;
    width: 106px;
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;
}

.notification-count {
    font-size: 18px;
    font-weight: bold;
    background: #EA8120;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    padding-top: 5px;
    float: right;
}

.notification-name {
    font-size: 15px;
    text-align: center;
    margin-top: 38px;
}

.notification-device {
    background: #8080809c;
}

    .notification-device > .notification-name {
        color: #ffffff;
    }

.notification-sensor {
    background: #ffffff;
}

    .notification-sensor > .notification-name {
        color: #EA8120;
    }

.notification-filter-close {
    font-size: 12px;
    color: #e8e8e8;
    text-align: center;
    cursor:pointer;
}

/* Automatic Programming */
.list-automatic-programming-content {
    margin-left: -10px !important;
    align-items: center;
}

.automatic-programming-days{
    font-size: 13px;
}

/* Profile */
.form-check-inline{
    display: block;
}

@media (max-width:576px) {
    .panel-menu-opcion {
        min-width: 20%;
        margin-left: 2%;
    }
    .rs-control {
        margin-bottom: 53px;
        display: inline-block;
    }
    .form-check {
        display: inline-block;
    }
    .tempIcon-Inside {
        left: 47%;
    }
    .nav .nav-item > .nav-link{
        border-radius : 0;
    }
}

@media (min-width:768px) {
    .panel-menu-opcion {
        min-width: 15%;
    }
}

@media (min-width:992px) {
    
}

@media (min-width:1200px) {
}